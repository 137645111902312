.services-full-width-banner {
  padding: 20px;
  background: darkolivegreen;
  color: #fff;
}

.services-wrapper {
  color: darkslategrey;
  width: 80%;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
}

.service-box {
  background: #fff;
  margin: 10px 0px;
  width: 19%;
  overflow: hidden;
  box-shadow: none;
  opacity: 0.6;
  transition: all 0.3s ease-in;
}

.service-box:hover {
  opacity: 1;
  cursor: pointer;
  box-shadow: 4px 4px 5px #222;
}

.service-box[data="true"] {
  opacity: 1;
}

.service-box .service-info {
  text-align: left;
  padding-left: 10px;
}

.main-content {
  height: fit-content;
}
