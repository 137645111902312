.header {
  width: 100%;
  background: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-around;
  position: fixed;
  z-index: 1000;
  top: 0px;
}

.header > div {
  height: 50px;
}

.header .nav-wrap {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
}

.header nav ul {
  list-style: none;
}

.header nav ul li {
  display: inline-block;
  padding: 10px;
}

.header nav ul li a {
  transition: color 0.3s ease;
}

.header nav ul li a:hover {
  cursor: pointer;
  color: gold;
}

.header nav ul li a.active {
  color: gold;
  border-bottom: 2px solid gold;
}

li.quote-CTA-button a {
  background: gold !important;
  border-radius: 5px;
  color: #fff;
  transition: background 0.5s linear;
}

li.quote-CTA-button a:hover {
  color: #fff !important;
  background: chartreuse !important;
}
