* {
  box-sizing: border-box;
  font-family: system-ui;
}

a {
  padding: inherit;
  color: initial;
  text-decoration: none;
}

body {
  margin: 0;
  background: gainsboro;
  text-align: center;
}

section {
  min-height: 100vh;
  padding-top: 70px;
}

.main-content {
  width: 80%;
  margin: 0px auto;
}
