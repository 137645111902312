.slideshow {
  height: inherit;
  max-height: 100vh;
  width: 100%;
  margin: 0px auto;
  position: relative;
}

.slide {
  position: absolute;
  top: 0;
  height: inherit;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.top-slide {
  animation: fadeOut 3s infinite;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.float-over-image {
  color: darkslateblue;
  position: absolute;
  top: calc(100vh / 2 - 125px);
  height: 150px;
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
}

.float-over-image h1 {
  font-size: 2.5em;
  margin: 0;
  line-height: 75px;
  border-bottom: 2px solid gainsboro;
}

.float-over-image p {
  font-size: 1.5em;
  margin: 0;
  line-height: 75px;
}
